import NextLink from "next/link";
import { GitBranch } from "lucide-react";
import { REPO_SOURCE } from "@ashgw/constants";
import { Button } from "@ashgw/ui";
export function RepoSourceCodeButton() {
  return <Button variant={"outline"} data-sentry-element="Button" data-sentry-component="RepoSourceCodeButton" data-sentry-source-file="RepoSourceCodeButton.tsx">
      <NextLink href={REPO_SOURCE} target="_blank" rel="noopener noreferrer" data-sentry-element="NextLink" data-sentry-source-file="RepoSourceCodeButton.tsx">
        <GitBranch strokeWidth={"1.5px"} size={"20px"} style={{
        display: "inline-block",
        marginLeft: "5px",
        marginBottom: "3px"
      }} data-sentry-element="GitBranch" data-sentry-source-file="RepoSourceCodeButton.tsx" />
      </NextLink>{" "}
    </Button>;
}