"use client";

import React, { useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";
import { LeftNav } from "./components/desktop/LeftNav";
import { RightNav } from "./components/desktop/RightNav";
import { BgOverlay } from "./components/mobile/BgOverlay";
import { DropDownMenu } from "./components/mobile/DropDownMenu";
import { HamburgerButton } from "./components/mobile/Hamburger";
import { Logo } from "./components/shared/Logo";
import { useToggleDropDownMenu } from "./hooks/useToggleDropDownMenu";
export function NavBar() {
  const [isOpened, toggleMenu] = useToggleDropDownMenu({
    menuId: "nav-menu"
  });
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  useEffect(() => {
    if (isOpened) {
      setIsOverlayVisible(true);
    } else {
      setIsOverlayVisible(false);
    }
  }, [isOpened]);
  const handleToggleMenu = () => {
    toggleMenu();
  };
  return <nav id="nav-menu" className="relative pt-3" data-sentry-component="NavBar" data-sentry-source-file="index.tsx">
      <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-source-file="index.tsx">
        {isOverlayVisible && <BgOverlay onClick={handleToggleMenu} />}
      </AnimatePresence>
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div id="hamburger" className="absolute inset-y-0 left-2 z-50 flex items-center sm:hidden">
            <HamburgerButton isOpened={isOpened} onClick={handleToggleMenu} data-sentry-element="HamburgerButton" data-sentry-source-file="index.tsx" />
          </div>
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex flex-shrink-0 items-center">
              <Logo data-sentry-element="Logo" data-sentry-source-file="index.tsx" />
            </div>
            <LeftNav data-sentry-element="LeftNav" data-sentry-source-file="index.tsx" />
          </div>
          <div className="z-50">
            <RightNav data-sentry-element="RightNav" data-sentry-source-file="index.tsx" />
          </div>
        </div>
      </div>
      <div className="mx-2 sm:hidden">
        <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-source-file="index.tsx">
          {isOpened ? <DropDownMenu onToggleMenu={handleToggleMenu} /> : null}
        </AnimatePresence>
      </div>
    </nav>;
}